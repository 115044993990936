<template>
  <el-dialog :title="$t('homeAlarm.name')" :visible.sync="visible" width="1000px" top="5vh">
    <el-row>
      <span>{{$t('homeAlarm.alarmType')}}：</span>
      <el-select v-model="alarmType">
        <template v-if="alarmStatus===0">
           <el-option v-for="(item, index) in options" :key="index" :label="item.name" :value="item.value"></el-option>
        </template>
        <template v-if="alarmStatus===1">
           <el-option v-for="(item, index) in options3" :key="index" :label="item.name" :value="item.value"></el-option>
        </template>
        <template v-if="alarmStatus===2">
           <el-option v-for="(item, index) in options2" :key="index" :label="item.name" :value="item.value"></el-option>
        </template>
       
      </el-select>
      <el-button type="primary" class="border" @click="fetchDataClick" :loading="tableLoading" plain>{{$t('homeAlarm.query')}}</el-button>
      <el-button type="primary" class="border" @click="exportData" :loading="exportLoading" plain>{{$t('homeAlarm.export')}}</el-button>
      <span style="color: #C0C4CC;font-size:12px;float:right;">* {{$t('homeAlarm.tip')}}</span>
    </el-row>
    <el-table :data="pageData" class="v-table__customStyle" height="450">
      <el-table-column type="index" width="50"></el-table-column>

      <el-table-column :label="$t('homeAlarm.vehicleName')" prop="VehicleName"></el-table-column>
      
      <el-table-column :label="$t('homeAlarm.deviceID')" prop="SIM2"></el-table-column>
      <el-table-column :label="$t('homeAlarm.alarmType')" prop="CAlarmType"></el-table-column>
      <el-table-column :label="$t('homeAlarm.alarmTime')" prop="GPSTime">
        <template slot-scope="{row}">
           <span>{{row.GPSTime ? miment(row.GPSTime).format() : ''}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('homeAlarm.alarmPlace')">
        <template slot-scope="{row}">
          {{row.Address}}
          <!-- <span-address :lon="row.Lon" :lat="row.Lat" show-default></span-address> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="v-pager"
      background
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change = "handleCurrentchange"
      :current-page="pageIndex"
      layout="total, sizes, prev, pager, next, jumper"
      :total="list.length"
      popper-class="custom">
    </el-pagination>
  </el-dialog>
</template>

<script>
import {GetAlarmDetail,ExportAlarmDetail} from '@/api/home'
import {GetAddressList} from '@/api/common'
import {saveBlob} from '@/common/utils'
import miment from 'miment'
export default {
  data() {
    return {
      miment,
      visible: false,
      list: [],
      pageData:[],
      tableLoading: false,
      exportLoading: false,
      alarmType: null,
      alarmStatus:0,
      pageSize: 10,
      pageIndex: 1,
      // options: [
      //   {name:'拆机报警',value:'拆机报警'},
      //   {name:'剪线报警',value:'剪线报警'},
      //   {name:'区域报警',value:'区域报警'},
      //   {name:'低电压报警',value:'低电压报警'}
      // ]
    }
  },
  created() {

  },
  computed: {
    options() {
      return [
        { name: this.$t('homeAlarm.alarmType1'), value: '低电压报警' },
        { name: this.$t('homeAlarm.alarmType9'), value: '低电量报警' },
        { name: this.$t('homeAlarm.alarmType2'), value: '拆机报警' },
        { name: this.$t('homeAlarm.alarmType10'), value: '断电报警' },
        { name: this.$t('homeAlarm.alarmType4'), value: '区域报警' },
        { name: this.$t('homeAlarm.alarmType11'), value: '围栏报警' },
      ]
    },
     options2() {
      return [
        { name: this.$t('homeAlarm.alarmType9'), value: '低电量报警' },
        { name: this.$t('homeAlarm.alarmType11'), value: '围栏报警' },
        { name: this.$t('homeAlarm.alarmType12'), value: '偏离路线报警' },
        { name: this.$t('homeAlarm.alarmType13'), value: '跌落报警' },
        { name: this.$t('homeAlarm.alarmType14'), value: '碰撞报警' },
      ]
    },
     options3() {
      return [
        { name: this.$t('homeAlarm.alarmType15'), value: '紧急报警' },
        { name: this.$t('homeAlarm.alarmType9'), value: '低电量报警' },
        { name: this.$t('homeAlarm.alarmType11'), value: '围栏报警' },
        { name: this.$t('homeAlarm.alarmType12'), value: '偏离路线报警' },
        { name: this.$t('homeAlarm.alarmType13'), value: '跌落报警' },
      ]
    },
    // pageData() {
    //   return this.list.slice(this.pageIndex*this.pageSize - this.pageSize, this.pageIndex*this.pageSize)
    // }
  },
  methods: {
    open(type,status) {
      if (type) {
        this.alarmType = type
        this.alarmStatus = status
        this.exportLoading = false
        this.tableLoading = false
      }
      this.pageSize = 10
      this.pageIndex = 1
      this.fetchData().then(res=>{
        this.fetchAddress(res)
      })
      this.visible = true
    },
    handleCurrentchange(val) {
      this.pageIndex = val
      let pageData = this.list.slice(this.pageIndex*this.pageSize - this.pageSize, this.pageIndex*this.pageSize)
      this.fetchAddress(pageData)
    },
    handleSizeChange(val) {
      this.pageSize = val
      let pageData = this.list.slice(this.pageIndex*this.pageSize - this.pageSize, this.pageIndex*this.pageSize)
      this.fetchAddress(pageData)
    },
    fetchData() {
      const promise = new Promise((resolve,reject) => {
        this.tableLoading = true
        GetAlarmDetail(this.alarmType,this.alarmStatus).then(ret => {
          this.tableLoading = false
          if (ret.data.errCode){
            return reject(ret.data)
          }
          // this.pageData = []
          this.list = []
          if (ret.data&&ret.data.length===0) {
            this.$message({
              type: 'info',
              message: this.$t('homeAlarm.tip2')
            })
          }
          let list = ret.data
          if(list){
            list.forEach(element => {
              this.options.forEach(item=>{
                if(element.CAlarmType===item.value){
                  element.CAlarmType = item.name
                }
              })
            });
          }
          this.list = list
          let pageData = this.list.slice(this.pageIndex*this.pageSize - this.pageSize, this.pageIndex*this.pageSize)
          resolve(pageData)
        }) 
      })
      return promise
    },
    exportData() {
      this.exportLoading = true
      ExportAlarmDetail(this.alarmType).then(ret => {
        this.exportLoading = false
        saveBlob(ret.data, `${this.$t('homeAlarm.name')}.xlsx`)
      }).catch(err => {
        this.exportLoading = false
      }).finally(() => {
        this.exportLoading = false
      })
    },
    fetchDataClick(){
      this.pageSize = 10
      this.pageIndex = 1
      this.fetchData().then(res=>{
        this.fetchAddress(res)
      })
    },
    fetchAddress(pages){
      let temp =[];
      pages.forEach(k=>{
        let item = {
          'Lon':k.Lon,
          'Lat':k.Lat,
          'ObjectID': k.VehicleID
        }
        temp.push(item)
      })
      const promise = new Promise((resolve) => {
        GetAddressList(temp).then((ret) => {
          if (ret.data.errCode){
            return reject(ret.data)
          }
          pages.forEach(k=>{
            ret.data.forEach(j=>{
              if(j.ObjectId === k.VehicleID){
               k.Address = j.Address
              }
            })
          })
          this.pageData = pages
          resolve(this.pageData)
        })
        
      })
      return promise
    },
    close() {

    }
  }
}
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 8px;
  .el-select {
    margin-right: 8px;
  }
}
.el-pagination {
  margin-top: 8px;
}
</style>


