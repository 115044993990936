import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function QueryContainer(pageIndex, pageSize, sortKey, sortDirect, filters) {
  const loginKey = getToken()
  return request({
    url: './query/QueryContainer',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters
    })
  })
}

export function QueryDevice(pageIndex, pageSize, sortKey, sortDirect, filters) {
  const loginKey = getToken()
  return request({
    url: './query/QueryDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters
    })
  })
}

export function QueryAlarm(filters) {
  const loginKey = getToken()
  return request({
    url: './query/QueryAlarms',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      filters
    })
  })
}

export function QueryContainerLogs(filters) {
  const loginKey = getToken()
  return request({
    url: './query/QueryContainerLogs',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      filters
    })
  })
}

export function QueryNewContainerLogs(filters, startTime) {
  const loginKey = getToken()
  return request({
    url: './query/QueryNewContainerLogs',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      filters,
      startTime
    })
  })
}

export function QueryNewAlarms(filters, startTime) {
  const loginKey = getToken()
  return request({
    url: './query/QueryNewAlarms',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      filters,
      startTime
    })
  })
}

export function QueryHistory(DeviceID, startTime, endTime) {
  const loginKey = getToken()
  return request({
    url: './query/QueryDeviceHistory',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      DeviceID,
      startTime,
      endTime
    })
  })
}

export function QueryContainerHistory(ContainerID, startTime, endTime) {
  const loginKey = getToken()
  return request({
    url: './query/QueryContainerHistory',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ContainerID,
      startTime,
      endTime
    })
  })
}

export function QueryHomeData() {
  const loginKey = getToken()
  return request({
    url: './query/QueryHomeData',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetHome_OnlineRate(TypeID) {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_OnlineRate',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      TypeID
    })
  })
}

export function GetHome_OnlineRate_af() {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_OnlineRate_af',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetDeviceIsOnline(TypeID) {
  const loginKey = getToken()
  return request({
    url: './query/GetDeviceIsOnline',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      TypeID
    })
  })
}

export function GetHome_Total() {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_Total',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetHome_Alarm() {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_Alarm',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetHome_Alarm_af(TypeID) {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_Alarm_af',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      TypeID
    })
  })
}

export function GetHome_Pay() {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_Pay',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetHome_OneYear(TypeID) {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_OneYear',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      TypeID
    })
  })
}

export function GetHome_OneYearActive() {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_OneYearActive',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetHome_OneYearActiveV2(range) {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_OneYearActiveV2',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      range
    })
  })
}

export function GetHome_ThreeYear(TypeID) {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_ThreeYear',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      TypeID
    })
  })
}

export function GetHome_Sim() {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_Sim',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetHome_Sim_af() {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_Sim_af',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetHome_Offline() {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_Offline',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetHome_Offline_af() {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_Offline_af',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetAlarmDetail(alarmType,TypeID) {
  const loginKey = getToken()
  return request({
    url: './query/GetAlarmDetail',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      alarmType,
      TypeID
    })
  })
}

export function GetAlarmDetail_af(alarmType, type) {
  const loginKey = getToken()
  return request({
    url: './query/GetAlarmDetail_af',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      alarmType,
      type
    })
  })
}

export function ExportAlarmDetail(alarmType) {
  const loginKey = getToken()
  return request({
    url: './query/ExportAlarmDetail',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      alarmType
    })
  })
}

export function ExportAlarmDetail_af(alarmType, type) {
  const loginKey = getToken()
  return request({
    url: './query/ExportAlarmDetail_af',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      alarmType,
      type
    })
  })
}

export function QueryAllSpread(TypeID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryAllSpread',
    method: 'post',
    timeout: 120000,
    data: Object.assign({}, commonParams, {
      loginKey,
      TypeID
    })
  })
}

export function QueryNowSpread(startTime, total) {
  const loginKey = getToken()
  return request({
    url: './query/QueryNowSpread',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      startTime,
      total
    })
  })
}

export function QueryRecentAlarm(TypeID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryRecentAlarm',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      TypeID
    })
  })
}

export function GetHome_WONO() {
  const loginKey = getToken()
  return request({
    url: './query/GetHome_WONO',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function ZD_QueryAlarm(HoldID,TypeID) {
  const loginKey = getToken()
  return request({
    url: './query/ZD_QueryAlarm',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldID,
      TypeID
    })
  })
}

export function RemoveAlarm(AlarmID,ObjectID) {
  const loginKey = getToken()
  return request({
    url: './delete/RemoveAlarm',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      AlarmID,
      ObjectID
    })
  })
}

